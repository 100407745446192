import "./App.css";
import FaqList from "./FaqList";
import { Box, Typography } from "@mui/material";

function App() {
  const heroImage = "https://i.imgur.com/yfF11yb.jpeg"
  return (
    <div>
      <Box
        sx={{
          height: "60vh", // Adjust height as needed
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${heroImage})`, // Replace with your image URL
          backgroundSize: "contain",
          backgroundPosition: "center",
          color: "#fff", // Text color
        }}
      ></Box>

      {/* Main Content */}
      <Box
        my={5}
        sx={{
          p: { xs: 0, lg: 4 }, // Padding 4 only on large devices
          textAlign: "center",
        }}
      >
      <Typography variant="h3" fontFamily={"Dancing Script, serif"}>
        Ola & Barti
      </Typography>
      <Typography variant="h4" fontFamily={"Dancing Script, cursive"}>
        15.08.2025
      </Typography>
      <Typography variant="h4" fontFamily={"Dancing Script, cursive"}>
        Katowice
      </Typography>
      <Typography variant="h5" fontFamily={"Dancing Script, cursive"}>
        Park Kościuszki
      </Typography>
      </Box>
      <Box>
        <FaqList />
      </Box>
    </div>
  );
}

export default App;
