import * as React from 'react';
import FaqItem from './FaqItem';
import { Container } from '@mui/material';

export default function FaqList() {
    const piesekImage = "https://i.imgur.com/hvrmTNe.jpeg"
const faqData = [
    {
        question: "Transport",
        answer: ["Wesele odbędzie się w restauracji Patio Park w Parku Kościuszki"],
        iframeSrc: "https://www.google.com/maps/embed/v1/place?key=AIzaSyDQ_Ao-DIExs3lQ7BAs-Zdw4ggY2zWrVlE&q=Patio-Park+%7C+Restauracja,Katowice+Poland"
    },
    {
        question: "Nocleg",
        answer: ["Wszyscy na Grzybowej"],
        iframeSrc: "https://www.google.com/maps/embed/v1/streetview?key=AIzaSyDQ_Ao-DIExs3lQ7BAs-Zdw4ggY2zWrVlE&location=50.2136807,18.9937673&heading=290&fov=100"
    },
    {
        question: "Potwierdzenie przybycia",
        answer: [
            "Bardzo prosimy o potwierdzenie przybycia do blabla ", 
            "Jeśli z jakiegoś powodu potrzebujecie więcej czasu na podjęcie decyzji, dajcie nam znać na blabla"
        ]
    },
    {
        question: "Ślub",
        answer: [
            "Ślub odbędzie się w kościele w parku Kościuszki / na Manhattanie, o godz tej i tej, proszę pojawić się tu i tam",
            "Więcej szczegółów wkrótce! :)"
        ],
        iframeSrc: "https://www.google.com/maps/embed/v1/place?key=AIzaSyDQ_Ao-DIExs3lQ7BAs-Zdw4ggY2zWrVlE&q=Kościół+Rzymskokatolicki+pw.+św.+Michała+Archanioła,Katowice+Poland"
    },
    {
        question: "Wesele",
        answer: [
            "Odbędzie się w Restauracji Patio Park zaraz po ślubie"
        ],
        iframeSrc: "https://www.google.com/maps/embed/v1/directions?key=AIzaSyDQ_Ao-DIExs3lQ7BAs-Zdw4ggY2zWrVlE&origin==Kościół+Rzymskokatolicki+pw.+św.+Michała+Archanioła,Katowice+Poland&destination=Patio-Park+%7C+Restauracja,Katowice+Poland&mode=walking"
    },
    {
        question: "Jedzenie/napoje",
        answer: [
            "Jeśli stosujecie dietę wegetariańską, wegańską, bezglutenową lub inną, prosimy o poinformowanie nas o tym przy potwierdzaniu przybycia."
        ]
    },
    {
        question: "Prezenty",
        answer: ["W kopercie albo na smyczy"],
        imageSrc: piesekImage
    },
    {
        question: "Ubiór & inne, dodatkowe info, kontakt, co tam jeszcze chcecie",
        answer: [
            "Proszę się ubrać ładnie i jak coś to dzwonić tu i tam",
            "Wszystko można dowolnie pozmieniać"
        ]
    }
];

return (
    <Container sx={{ paddingLeft: { xs: 0, lg: 4 }, paddingRight: { xs: 0, lg: 4 } }}>
        {faqData.map((faq, index) => (
            <FaqItem key={index} question={faq.question} answer={faq.answer} iframeSrc={faq.iframeSrc} imageSrc={faq.imageSrc}/>
        ))}
    </Container>
);
}